import React, { useState } from 'react';
import BinaryQuestion from './components/BinaryQuestion';
import DynamicRadioInput from './components/DynamicRadioInput'

interface FormData {
  direitoArrependimento: string;
  descumprimentoOferta: string;
  extravioDefinitivo: string;
  faixaIntervaloExtravioTemporario: string;
  violacaoFurtoAvaria: string;
  cancelamentoAlteracaoDestino: string;
  faixaIntervaloAtraso: string;
  culpaExclusivaConsumidor: string;
  condicoesClimaticasFechamentoAeroporto: string;
  noshow: string;
  overbooking: string;
  assistenciaCiaAerea: string;
  hipervulneravel: string;
}

const faixasIntervaloExtravio = [
  { value: '0', displayedName: "Não houve extravio"},
  { value: '1', displayedName: "1h - 24h"},
  { value: '2', displayedName: "25h - 72h"},
  { value: '3', displayedName: "73h - 168h"},
  { value: '4', displayedName: "Acima de 169h"}
];

const faixasIntervaloAtraso = [
  { value: '0', displayedName: "Não houve atraso"},
  { value: '1', displayedName: "1h - 3h59m"},
  { value: '2', displayedName: "4h - 7h59m"},
  { value: '3', displayedName: "8h - 11h59m"},
  { value: '4', displayedName: "12h - 15h59m"},
  { value: '5', displayedName: "16h - 23h59m"},
  { value: '6', displayedName: "24h - 27h59m"},
  { value: '7', displayedName: "Acima de 28hr"},
];

function App() {
  const [formData, setFormData] = useState<FormData>({
    direitoArrependimento: '',
    descumprimentoOferta: '',
    extravioDefinitivo: '',
    faixaIntervaloExtravioTemporario: '',
    violacaoFurtoAvaria: '',
    cancelamentoAlteracaoDestino: '',
    faixaIntervaloAtraso: '',
    culpaExclusivaConsumidor: '',
    condicoesClimaticasFechamentoAeroporto: '',
    noshow: '',
    overbooking: '',
    assistenciaCiaAerea: '',
    hipervulneravel: '',
  });
  const [response, setResponse] = useState('')
  const [responseSuccess, setResponseSuccess] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
        const response = await fetch('https://api.concilia.ufsc.br/predict', {
        // const response = await fetch('http://127.0.0.1:5000/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
      if (!response.ok) {
        const errorMsg = result['error']
        throw new Error(errorMsg);
      }

      setResponseSuccess(true)
      setResponse(String(result['prediction']))
  
      // console.log(result);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setResponseSuccess(false)
      setResponse('Não foi possível realizar a requisição')
    }
  };

  return (
    <div>
      <header className="w-full flex h-28 bg-gray-950">
        <div className="w-1/6 flex justify-center items-center">
          <img className="w-20 h-18 mx-auto my-auto" src={`${process.env.PUBLIC_URL}/concilia_logo.png`} alt="Logo do projeto ConcilIA em branco com fundo escuro." />
        </div>
        <p className="w-5/6 my-auto text-4xl font-mono text-slate-100 font-mono ml-6">
          <span className="font-thin">Concil-</span><span className="font-black">IA</span>
        </p>
      </header>

      <div
        className="w-full bg-gray-300 flex"
      >
        <div className="bg-white p-6 pb-64 rounded border border-gray-300 shadow-md mx-auto w-10/12 lg:w-4/6">
          <div className="border border-gray-300 rounded bg-gray-100 h-24 p-2 flex align-center">
            <p className="my-auto mx-auto text-lg text-gray-800">Esta é uma ferramenta em fase de testes e desenvolvimento.</p>
          </div>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 lg:grid-cols-2 my-4">
            {/* Radio buttons */}
            <div className="space-y-5">
              <BinaryQuestion
                question="Direito de arrependimento"
                name="direitoArrependimento"
                value={formData.direitoArrependimento}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Descumprimento de oferta"
                name="descumprimentoOferta"
                value={formData.descumprimentoOferta}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Extravio definitivo"
                name="extravioDefinitivo"
                value={formData.extravioDefinitivo}
                onChange={handleChange}
              />
              <DynamicRadioInput
                question="Faixa intervalo extravio temporário"
                name="faixaIntervaloExtravioTemporario"
                value={formData.faixaIntervaloExtravioTemporario}
                onChange={handleChange}
                options={faixasIntervaloExtravio}
              />
              <BinaryQuestion
                question="Violação, furto ou avaria"
                name="violacaoFurtoAvaria"
                value={formData.violacaoFurtoAvaria}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Cancelamento ou alteração de destino"
                name="cancelamentoAlteracaoDestino"
                value={formData.cancelamentoAlteracaoDestino}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Culpa exclusiva do consumidor"
                name="culpaExclusivaConsumidor"
                value={formData.culpaExclusivaConsumidor}
                onChange={handleChange}
              />
              </div>
              <div className="space-y-5 mt-4 lg:mt-0">
              <DynamicRadioInput 
                question="Faixa de atraso"
                value={formData.faixaIntervaloAtraso}
                onChange={handleChange}
                options={faixasIntervaloAtraso}
                name={"faixaIntervaloAtraso"}
              />
              <BinaryQuestion
                question="Condições climáticas do aeroporto"
                name="condicoesClimaticasFechamentoAeroporto"
                value={formData.condicoesClimaticasFechamentoAeroporto}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="No show"
                name="noshow"
                value={formData.noshow}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Overbooking"
                name="overbooking"
                value={formData.overbooking}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Assistência companhia aérea"
                name="assistenciaCiaAerea"
                value={formData.assistenciaCiaAerea}
                onChange={handleChange}
              />
              <BinaryQuestion
                question="Hipervulnerável"
                name="hipervulneravel"
                value={formData.hipervulneravel}
                onChange={handleChange}
              />
              <button type="submit" className="h-12 w-28 bg-blue-500 text-white font-semibold mt-2 px-4 py-2 rounded">
                Consultar
              </button>
            </div>
          </form>
          {response && (
            <div className={`flex justify-center text-base font-medium my-8 p-4 border-t-4 rounded ${responseSuccess ? "bg-green-100 border-green-500 text-green-700" : "bg-red-100 border-red-500 text-red-700"}`}>
              <p>{responseSuccess && "Previsão de danos morais:"} {response}</p>
            </div>
          )}
        </div>
      </div>

    </div>
  );
}

export default App;
