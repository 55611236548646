import React, { ChangeEvent } from 'react';

interface BinaryQuestionProps {
  question: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function BinaryQuestion({ question, name, value, onChange }: BinaryQuestionProps) {

    return (
      <fieldset>
        <legend className="font-medium">{question}</legend>
          <div className="flex space-x-8 mt-1">
            <label>
              <input
                className="mx-2"
                name={name}
                type="radio"
                value="1"
                checked={value === "1"}
                onChange={onChange}
                aria-checked={value === "1"}
              />
              Sim
            </label>
            <label>
              <input
                className="mx-2"
                name={name}
                type="radio"
                value="0"
                checked={value === "0"}
                onChange={onChange}
                aria-checked={value === "1"}
                />
              Não
            </label>
          </div>
      </fieldset>
    );
}

export default BinaryQuestion;