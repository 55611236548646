import React from 'react';

type Option = {
  value: string;
  displayedName: string
};

type RadioInputProps = {
  question: string
  options: Option[];
  value: string;
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioInput: React.FC<RadioInputProps> = ({ question, options, value, name, onChange}) => {
    
    return (
        <fieldset>
            <legend className="font-medium">{question}</legend>
            <div className="grid lg:grid-flow-col lg:grid-rows-5 gap-1 mt-1">

            {options.map((option, optionIndex) => (
                <label key={optionIndex} className="w-44">
                <input
                    className="mx-2"
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={value === option.value}
                    onChange={onChange}
                    />
                {option.displayedName}
                </label>
            ))}
            </div>
        </fieldset>
    );
};

export default RadioInput;